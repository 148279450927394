import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { isRunTime } from "../../util/generalUtil"
import { colors } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  height: 100%;
  font-size: 0.9em;
  a {
    display: block;
    width: 100%;
    color: white;
    font-size: 0.9em;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;

    background-color: ${({ selected }) => (selected ? colors.primaryEvenDarker : "none")};

    &:hover {
      background-color: ${colors.primaryDarkerHighlight};
      text-decoration: none;
      color: white;
    }

    i {
      width: 40px;
    }
  }

  button {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    display: block;
    width: 100%;
    color: white;
    font-size: 0.9em;
    font-weight: bold;
    border: none;
    text-align: left;
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? colors.lightGray : "white")};

    &:hover {
      background-color: ${colors.grey};
      text-decoration: none;
    }

    i {
      width: 40px;
    }
  }
`

interface Props {
  to?: string
  onClick?: () => void
  selected?: boolean
  label: string
  icon: string
}

const HeaderMenuItem: FC<Props> = ({ to, selected, onClick, label, icon }) => {
  return (
    <Container selected={selected || (isRunTime() && window.location.pathname === to)}>
      {onClick && <button onClick={onClick}>{label}</button>}
      {to && <GLink to={to}>{label}</GLink>}
    </Container>
  )
}

export default HeaderMenuItem
