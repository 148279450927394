import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import {
  colors,
  CustomerType,
  FullLinkButton,
  LoginContext,
  Portal,
  zIndexs,
} from "@social-supermarket/social-supermarket-components"
import { Icon } from "semantic-ui-react"
import { navigate } from "gatsby"

const Container = styled.div`
  height: 50px;
  margin-left: auto;
  display: flex;
  align-items: center;
`
const StarterMember = styled.div`
  background-color: ${colors.primaryLight};
  border-radius: 10px;
  font-size: 0.6em;
  font-weight: bold;
  padding: 5px 15px;
  text-transform: uppercase;
`
const FullMember = styled.div`
  background-color: ${colors.primaryLight};
  border-radius: 10px;
  font-size: 0.6em;
  font-weight: bold;
  padding: 5px 15px;
  text-transform: uppercase;
`

const ProfileCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
  color: white;
  height: 100%;
  padding: 20px;

  i {
    height: 23px !important;
    margin: 5px !important;
    color: white;
  }
`

const DropDownMenu = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  padding: 10px;
  z-index: ${zIndexs.profileZIndex};

  width: 280px;
  height: 160px;
  box-shadow: ${colors.boxShadow};
  background-color: white;
`

const Title = styled.div`
  font-weight: bold;
  margin-left: 10px;
  margin-top: 5px;
`
const Email = styled.div`
  font-size: 0.8em;
  margin-bottom: 20px;
  margin-left: 10px;
`

const StyledFullLinkButton = styled(FullLinkButton)`
  margin-bottom: 5px;
`

interface Props {}

const ProfileMenu: FC<Props> = ({}) => {
  const [active, setActive] = useState<boolean>(false)
  const { user, onLogOut } = useContext(LoginContext)

  const handleEnter = () => {
    setActive(true)
  }
  const handleLeave = () => {
    setActive(false)
  }
  const handleLogout = () => {
    onLogOut()
    setActive(false)
  }
  const handleEditProfile = () => {
    navigate("/profile")
  }
  return (
    user && (
      <Container onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
        {user.modules.includes("PLATFORM_STARTER") && <StarterMember>starter member</StarterMember>}
        {user.modules.includes("PLATFORM_FULL") && <FullMember>full member</FullMember>}
        <ProfileCircle>
          {getInitials(user)}
          <Icon name={"user"} />
        </ProfileCircle>
        {active && (
          <Portal>
            <DropDownMenu>
              <Title>
                {user.firstName} {user.lastName}
              </Title>
              <Email>{user.email}</Email>

              <StyledFullLinkButton onClick={handleEditProfile}>Edit Profile</StyledFullLinkButton>

              <StyledFullLinkButton onClick={handleLogout}>Log Out</StyledFullLinkButton>
            </DropDownMenu>
          </Portal>
        )}
      </Container>
    )
  )
}

const getInitials = (user: CustomerType) => {
  return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
}

export default ProfileMenu
