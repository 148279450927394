import * as React from "react"
import styled from "styled-components"
import { FC, useContext } from "react"
import HeaderMenuItem from "./HeaderMenuItem"
import { LoginContext } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  height: 100%;
  display: flex;
`

interface Props {}

const HeaderMenu: FC<Props> = () => {
  const { user } = useContext(LoginContext)
  return (
    <Container>
      <HeaderMenuItem to="/gifting" icon="send" label="Gifting" />
      <HeaderMenuItem to="/supplies" icon="send" label="Supplies" />
      <HeaderMenuItem to="/impact" icon="dashboard" label="Your Impact" />
      <HeaderMenuItem to="/guide" icon="help" label="Help" />
      {user && user.roles.includes("administrator") && (
        <HeaderMenuItem to="/admin" icon="admin" label="Admin" />
      )}
    </Container>
  )
}

export default HeaderMenu
