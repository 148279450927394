import * as React from "react"
import { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ImageType from "../../social-supermarket/model/ImageType"
import GImage from "../GImage"
import GLink from "../GLink"
import { StaticImage } from "gatsby-plugin-image"

interface Props {}

const GInvertedLogo: FC<Props> = () => {
  return (
    <GLink to="/">
      <StaticImage src="../../images/inverted_logo.png" alt="Logo" layout="fixed" width={40} />
    </GLink>
  )
}

export default GInvertedLogo
